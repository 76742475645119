<template>
  <div>
    <div class="sales-img">
      <img :src="fileBaseUrl + contentData.salesImage" />
    </div>
    <div class="sales-desc">
      {{ contentData.salesContent }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      fileBaseUrl: this.$baseUrl
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.sales-desc {
  margin-top: 20px;
  font-size: 9px;
  font-weight: 400;
  color: #333333;
}
.sales-img img:hover{
  transform: scale(1.35);
}
</style>
