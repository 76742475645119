<template>
  <a-row>
    <a-col :lg="10" :xs="24">
      <div class="image-container">
        <video :src="videoSrc" style="margin-bottom:20px;" controls="controls" ref="video"></video>
        <img :src="fileBaseUrl + contentData.briefIntroductionImage" />
      </div>
    </a-col>
    <a-col :lg="14" :xs="24">
      <MoreContent :content="htmlStr" :show-lines="12" is-html></MoreContent>
    </a-col>
  </a-row>
</template>

<script>
import i18nMixin from '@/store/i18n-mixin'
export default {
  mixins: [i18nMixin],
  props: {
    contentData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    videoSrc () {
      const videoObj = {
        'ar-SA': require('../../../../assets/video/ar-company.mp4'),
        'en-US': require('../../../../assets/video/en-company.mp4'),
        'es-ES': require('../../../../assets/video/es-company.mp4'),
        'zh-CN': require('../../../../assets/video/zh-company.mp4')
      }
      return videoObj[this.currentLang]
    },
    htmlStr () {
      const str = this.contentData.briefIntroduction || ''
      return str.replace(new RegExp('\\n+', 'g'), '\n')
    }
  },
  mounted () {
    this.$refs.video.autoplay = true
  },
  data () {
    return {
      fileBaseUrl: this.$baseUrl
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.image-container {
  margin: 0 20px 20px 0;

  img {
    width: 100%;
  }
}
</style>
