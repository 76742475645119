<template>
  <a-row :gutter="10">
    <a-col :lg="12" :xs="24" class="contact-map">
      <TencentMap :lat-lng="latLng"></TencentMap>
    </a-col>
    <a-col :lg="12" :xs="24" class="contact-item">
      <div class="contact-info">
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }" style="width:100%;">
          <a-form-item :label="$t('aboutUs.contact.address')">
            {{ contentData.address ||'-' }}
          </a-form-item>
          <a-form-item :label="$t('aboutUs.contact.linkMan')">
            {{ contentData.linkMan ||'-' }}
          </a-form-item>
          <a-form-item :label="$t('aboutUs.contact.tel')">
            {{ contentData.phone ||'-' }}
          </a-form-item>
          <a-form-item :label="$t('aboutUs.contact.email')">
            {{ contentData.email ||'-' }}
          </a-form-item>
           <a-form-item :label="$t('aboutUs.contact.url')">
            {{ contentData.url ||'-' }}
          </a-form-item>
        </a-form>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    contentData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      latLng: ''
    }
  },
  mounted () {
    this.latLng = `${this.contentData.lat || '34.259634'},${this.contentData.lng || '117.263174'}`
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.contact-map {
  height: 220px;
  margin-bottom: 20px;
}
.contact-item {
  height: 220px;
}
.contact-info {
  background: #E8E8E8;
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;

  & /deep/ .ant-form-item {
    margin-bottom: 4px;
  }
  & /deep/ .ant-form-item-control, & /deep/ .ant-form-item-label {
    line-height: 24px;
  }
}
@media (max-width: 575px) {
  .contact-item {
    height: unset;
    min-height: 220px;
  }
}
</style>
