<template>
  <div>
    <Statistic :contents="companyObj.parameter"></Statistic>
    <ContentArea>
      <Tabs v-model="activeTabKey">
        <a-tab-pane key="1" :tab="$t('aboutUs.profile')">
          <CompanyProfile :content-data="profile" v-if="activeTabKey==='1'"></CompanyProfile>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('aboutUs.contact')">
          <ContactUs :content-data="contacts"></ContactUs>
        </a-tab-pane>
        <a-tab-pane key="3" :tab="$t('aboutUs.qualification')">
          <Qualification :content="companyObj.certificationImages"></Qualification>
        </a-tab-pane>
        <a-tab-pane key="4" :tab="$t('aboutUs.culture')">
          <CompanyCulture :contents="companyObj.cultrueTitle"></CompanyCulture>
        </a-tab-pane>
        <a-tab-pane key="5" :tab="$t('aboutUs.exhibition')">
          <Exhibition></Exhibition>
        </a-tab-pane>
        <a-tab-pane key="6" :tab="$t('aboutUs.sales')">
          <SalesNetwork :content-data="sales"></SalesNetwork>
        </a-tab-pane>
      </Tabs>
    </ContentArea>
  </div>
</template>
<script>
import Statistic from './modules/aboutUs/Statistic'
import CompanyProfile from './modules/aboutUs/CompanyProfile'
import ContactUs from './modules/aboutUs/ContactUs'
import Qualification from './modules/aboutUs/Qualification'
import CompanyCulture from './modules/aboutUs/CompanyCulture'
import SalesNetwork from './modules/aboutUs/SalesNetwork'
import Exhibition from './modules/aboutUs/Exhibition'

export default {
  name: 'AboutUs',
  components: {
    Statistic,
    CompanyProfile,
    ContactUs,
    Qualification,
    CompanyCulture,
    SalesNetwork,
    Exhibition
  },
  data () {
    return {
      activeTabKey: '1',
      companyObj: {},
      profile: {},
      sales: {},
      contacts: {}
    }
  },
  created () {
    this.$aboutUs.companyInfo().then(res => {
      if (!res.data) {
        this.$message.warn(this.$t('common.noData'))
        return
      }
      this.companyObj = this.$json2object(res.data)
      const { briefIntroduction, briefIntroductionImage, salesContent, salesImage, bizCompanyAbout = {} } = this.companyObj
      this.profile = { briefIntroduction, briefIntroductionImage }
      this.sales = { salesContent, salesImage }
      this.contacts = bizCompanyAbout
    })
  }
}
</script>
<style lang="less" scoped>
</style>
