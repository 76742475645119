<template>
  <div class="statitic-container">
    <div class="content-container">
      <div v-for="(item,idx) of nums" :key="idx" class="content-item">
        <v-countup :end-value="item.newVal" :options="{suffix: item.suffix}" class="val"></v-countup>
        <div class="lable">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contents: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    nums () {
      return this.contents.map(c => {
        const reg = /^(\d+)(\D?)/
        const matchRes = c.value.match(reg)
        c.newVal = matchRes[1]
        c.suffix = matchRes[2]
        return c
      })
    }
  },
  data () {
    return {
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.statitic-container {
  height: 225px;
  background-image:url('~@/assets/images/home/banner2.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;

  .content-container {
    display: flex;
    flex-wrap: wrap;

    .content-item {
      width: calc((100% - 10px * 2) / 3);
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      text-align: center;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .val {
        font-size: 27px;
        font-weight: bold;
        color: #FFFFFF;
      }

      .lable {
        border: 1px solid #FFFFFF;
        font-size: 10px;
        font-weight: 400;
        color: #FFFFFF;
        padding: 4px 0;
      }
    }
  }
}

@media screen and (min-width:1024px) {
  .content-container {
    width: 40%;
    justify-content: flex-start;
    margin-left: 10%;
  }
}
@media screen and (max-width:1000p) {
  .content-container {
    width: 90%;
  }
}
</style>
