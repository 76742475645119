<template>
  <div>
    <div class="exhibition-container">
      <div class="exhibition-item" v-for="(item,idx) of dataList" :key="idx" @click="go2detail(item.exhibitionId)">
        <div class="top">
          <img :src="fileBaseUrl + item.image" />
        </div>
        <div class="year">{{ item.label }}</div>
        <div class="content-container">
          <div class="content">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="desc">
              {{ item.brief }}
            </div>
          </div>
          <div class="operation">
            {{ $t('common.more') }}
            <span class="iconfont gw-right-arrow"></span>
          </div>
        </div>
      </div>
    </div>
    <a-pagination v-model="current" :page-size="3" :total="total" @change="onPageNumChange" :item-render="itemRender" class="flex justify-center" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      fileBaseUrl: this.$baseUrl,
      dataList: [],
      current: 1,
      total: 0
    }
  },
  created () {
    this.getExhibitions()
  },
  methods: {
    itemRender (current, type, originalElement) {
      if (type === 'prev') {
        return ''
      } else if (type === 'next') {
        return ''
      }
      return originalElement
    },
    onPageNumChange (current) {
      this.current = current
      this.getExhibitions()
    },
    getExhibitions () {
      this.$aboutUs.exhibitionList(this.current).then(res => {
        if (!res.rows || res.rows.length === 0) {
          this.$message.warn(this.$t('common.noData'))
          return
        }
        this.dataList = res.rows
        this.total = res.total
      })
    },
    go2detail (val) {
      this.$router.push({ name: 'ExhibitionDetail', query: { id: val } })
    }
  }
}
</script>
<style lang="less" scoped>
  .exhibition-container {
    display: flex;
    flex-wrap: wrap;

    .exhibition-item {
      cursor: pointer;
      flex: none;
      margin-bottom: 10px;
      background: rgba(241,240,238,0.13);
      box-shadow: 0px 2px 4px 0px rgba(141,133,92,0.35);

      .top {
        img {
          width: 100%;
        }
      }

      .year {
        position: absolute;
        transform: translate(18px, -50%);
        background: #376E25;
        width: 45px;
        padding: 4px 10px;
        font-size: 10px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
      }

      .content-container {
        padding: 0 10px 10px;
        margin-top: 40px;

        .content {
          .title {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }

          .desc {
            margin-top: 10px;
            font-size: 9px;
            font-weight: 400;
            color: #666666;
          }
        }

        .operation {
          margin-top: 30px;
          color: #376E25;
        }
      }
    }

    @media screen and (min-width:1024px) {
      .exhibition-container {
        justify-content: flex-start;
      }

      .exhibition-item {
        width: calc((60vw - 10px * 2) / 3);
        margin-right: 10px;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    @media screen and (max-width:1000px) {
      .exhibition-item {
        width: 90vw;
      }
    }
  }
</style>
