<template>
  <div class="culture-container">
    <div class="culture-item" v-for="(item,idx) of contents" :key="idx">
      <div class="top">
        {{ item.name }}
      </div>
      <div class="content">
        <img :src="fileBaseUrl + item.url" />
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contents: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      fileBaseUrl: this.$baseUrl
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
  .culture-container {
    display: flex;
    flex-wrap: wrap;

    .culture-item {
      flex: none;
      margin-bottom: 10px;
      font-size: 14px;

      .top {
        background: #376E25;
        height: 30px;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px 10px 0 0;
      }

      .content {
        background: #E8E8E8;
        height: 150px;
        color: #333333;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 10px;
        border-radius: 0 0 10px 10px;

        img {
          width: 40px;
          margin: 20px 0 10px 0;
        }
      }
    }

    @media screen and (min-width:1024px) {
      .culture-container {
        justify-content: flex-start;
      }

      .culture-item {
        width: calc((60vw - 10px * 2) / 3);
        margin-right: 10px;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    @media screen and (max-width:1000px) {
      .culture-item {
        width: 90vw;
      }
    }
  }
</style>
