<template>
  <div>
    <div class="qualify-container">
      <div class="qualify-item" v-for="(item,idx) of imgs" :key="idx">
        <img :src="fileBaseUrl + item" />
      </div>
    </div>
    <div class="qualify-last">
      <div class="qualify-item">
        <img :src="fileBaseUrl + lastImg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    imgs () {
      const data = this.content.split(',') || []
      data.pop()
      return data
    },
    lastImg () {
      return this.content.split(',').pop()
    }
  },
  data () {
    return {
      fileBaseUrl: this.$baseUrl
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.qualify-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.qualify-item {
  margin-bottom: 10px;
}

.qualify-last {
  display: flex;
  justify-content: center;
}

@media screen and (min-width:1024px) {
  .qualify-container {
    justify-content: flex-start;
  }

  .qualify-item {
    width: calc((60vw - 20px * 3) / 4);
    margin-right: 20px;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

@media screen and (max-width:1000px) {
  .qualify-container, .qualify-last {
    justify-content: center;
  }
}
</style>
